.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.sector-ico{
   color: #0a5094;
   border: solid 1px #0a5094;
   font-size: 28px;
   border-radius: 5px;
   margin-right: 5px;
   padding: 3px;
}

.buyerSeller-Popup {
  border: 1px solid #ececec;
  border-radius: 15px;
  padding: 15px;
}
.deal{
  min-height: auto !important;
  height: auto;
}
.deal .product-title {
  max-width: 100%;
  margin-bottom: 15px;
  font-size: 32px;
  line-height: 1.23;
  text-align: center;
}
.featured-card.bg1{
  background: linear-gradient(rgba(10, 80, 148, .9), rgba(0, 0, 0, .9)),
      url('../public/assets/imgs/bg/bg1.jpg')no-repeat center center/cover;
  color: #fff;
}
.featured-card.bg2 {
  background: linear-gradient(rgba(10, 80, 148, .9), rgba(0, 0, 0, .9)),
    url('../public/assets/imgs/bg/bg2.jpg')no-repeat center center/cover;
  color: #fff;
}
.featured-card.bg3 {
  background: linear-gradient(rgba(10, 80, 148, .9), rgba(0, 0, 0, .9)),
    url('../public/assets/imgs/bg/bg3.jpg')no-repeat center center/cover;
  color: #fff;
}
.featured-card.bg4 {
  background: linear-gradient(rgba(10, 80, 148, .9), rgba(0, 0, 0, .9)),
    url('../public/assets/imgs/bg/bg4.jpg')no-repeat center center/cover;
  color: #fff;
}
.featured-card.bg5 {
  background: linear-gradient(rgba(10, 80, 148, .9), rgba(0, 0, 0, .9)),
    url('../public/assets/imgs/bg/bg5.jpg')no-repeat center center/cover;
  color: #fff;
}
.featured-card.bg6 {
  background: linear-gradient(rgba(10, 80, 148, .9), rgba(0, 0, 0, .9)),
    url('../public/assets/imgs/bg/bg6.jpg')no-repeat center center/cover;
  color: #fff;
}
.featured-card.bg7 {
  background: linear-gradient(rgba(10, 80, 148, .9), rgba(0, 0, 0, .9)),
    url('../public/assets/imgs/bg/bg7.jpg')no-repeat center center/cover;
  color: #fff;
}
.featured-card.bg8 {
  background: linear-gradient(rgba(10, 80, 148, .9), rgba(0, 0, 0, .9)),
    url('../public/assets/imgs/bg/bg8.jpg')no-repeat center center/cover;
  color: #fff;
}
.featured-card.bg9 {
  background: linear-gradient(rgba(10, 80, 148, .9), rgba(0, 0, 0, .9)),
    url('../public/assets/imgs/bg/bg9.jpg')no-repeat center center/cover;
  color: #fff;
}

.featured-card.bg1 p,
.featured-card.bg2 p,
.featured-card.bg3 p,
.featured-card.bg4 p,
.featured-card.bg5 p,
.featured-card.bg6 p,
.featured-card.bg7 p,
.featured-card.bg8 p,
.featured-card.bg9 p{
  color: #fff;
  text-align: justify;
}
.home-slide-cover{overflow: hidden;}

.carausel-8-columns-cover .slick-track {
  display: flex !important;
  gap: 15px;
  /* Alternatively, use gap for modern browsers */
}
.carausel-8-columns-cover .carausel-8-columns {
  overflow: hidden;
  margin: 0;
}
.slick-prev {
  left: 25px !important;
  z-index: 99;
}
.slick-next {
    right: 25px !important;
    z-index: 99;
  }

.carausel-3-columns-cover .slick-slide {
      margin: 0 10px;
      /* Adjust slide gap here */
}
  
.carausel-3-columns-cover .slick-list {
      margin: 0 -10px;
      /* Compensates for the slide margin */
}
  
.carausel-3-columns-cover .carousel-slide img {
      width: 100%;
      /* Ensures images are fully responsive */
      border-radius: 8px;
      /* Optional: Add rounded corners to images */
}

.single-content{ color: #000 !important;}
.single-hero-slider .display-2{font-size: 52px !important; line-height: normal; color:#fff !important;}
.slick-dots {
  bottom: 5px !important;
}
.slick-dots li button:before {
  top: -1px !important;
  left: -3px !important;
}
.dot-style-1 ul li button {
  border-color: #fff !important;
}
.hero-slider-1 .slider-content p {
  font-size: 22px;
  color: #fff;
}
.hotline p {
  font-size: 22px !important;
  font-weight: 700;
}
.footer-link-widget:not(:last-child) {
  margin-right: 0;
}
/* Style for the success/error message */
.message {
  color: green;
  /* Default color for success */
  margin-bottom: 15px;
  transition: opacity 0.5s ease;
}

.message.error {
  color: red;
  /* Error color */
}

/* Loader style */
.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
  margin-top: 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}